import React from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import style from "./successModal.module.scss";
import { useNavigate } from "react-router-dom";

function SuccessModal(props) {
  const navigate = useNavigate();

  const handelCheck = () => {
    navigate("/");
  };
  return (
    <section>
      <div className={style.holdModalAll}>
        <div className={style.holdModalAll2}>
          <div>
            <AiOutlineCheckCircle className={style.close} />
          </div>
          <p className={style.successText}>Successful</p>
          <div className={style.holdClose}>
            <button onClick={handelCheck}>Close</button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SuccessModal;
