import React, { useEffect } from "react";
import Style from "./map.module.scss";
import Form from "../Form/Form";

function Map(props) {
  useEffect(() => {
    const ifameData = document.getElementById("iframeId");
    const lat = 6.440065356936118;
    const lon = 3.464997996111175;
    ifameData.src = `https://maps.google.com/maps?q=${lat},${lon}&hl=es;&output=embed`;
  });

  return (
    <section className={Style.holdShould} id="form1">
      <div className={Style.holdMapText}>
        <h1>
          Experience The biggest <span>urban</span> and <span>lifestyle</span>{" "}
          city
        </h1>
        <p>
          Fill out the form and a member of the team would reach out to you and
          break down your land acquisition process.
        </p>
      </div>
      <div className={Style.holdShould2}>
        <Form />
        <div className={Style.holdShould3}>
          <div className={Style.map}>
            <iframe id="iframeId" title="Map"></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Map;
