import React from 'react';
import Style from "./Enjoyment.module.scss";
import skyline from "../../Asset/skyline.png";

function Enjoyment(props) {
    return (
      <section className={Style.Investment1}>
        <div className={Style.Investment2}>
          <div className={Style.Investment3}>
            <div className={Style.Investment4}>
              <img src={skyline} alt="skyline" />
            </div>
            <div className={Style.Investment5}>
              <h1>The Pinnacle of Urban Living</h1>
            </div>
            <div className={Style.Investment6}>
              <p>
                 Kapital city estate is described as the pinnacle of urban
                living. This implies that Kapital city estate is the highest and
                most desirable standard of living in the heart of New Lagos
                (EPE).
              </p>
            </div>
          </div>
          <div className={Style.Investment3}>
            <div className={Style.Investment4}>
              <img src={skyline} alt="skyline" />
            </div>
            <div className={Style.Investment5}>
              <h1>Luxury Living with the Kapital Lifestyle</h1>
            </div>
            <div className={Style.Investment6}>
              <p>
                Experience Luxury living in Kapital city estate. The estate
                promises a conducive and safe living environment which can be
                attractive for High Net-worth Individuals seeking a peaceful and
                harmonious community.
              </p>
            </div>
          </div>
          <div className={Style.Investment3Pro}>
            <div className={Style.Investment4}>
              <img src={skyline} alt="skyline" />
            </div>
            <div className={Style.Investment5}>
              <h1>Key to your ideal city life</h1>
            </div>
            <div className={Style.Investment6}>
              <p>
                Experience the tranquility of living amidst nature's embrace,
                with scenic walking trails and serene park areas within the
                estate.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Enjoyment;