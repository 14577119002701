import React from 'react';
import Hero from '../Components/Hero/Hero';
import PartnerShip from '../Components/PartnerShip/PartnerShip';
import Enjoyment from '../Components/Enjoyment/Enjoyment';
import HouseFrom from "../Components/HouseFrom/HouseFrom";
import Features from "../Components/Features/Features";
import Pricing from "../Components/Pricing/Pricing";
import LandMark from "../Components/LandMark/LandMark";
import ClientFeedback from "../Components/ClientFeedback/ClientFeedback";
import Team from "../Components/Team/Team";
import Callus from "../Components/Callus/Callus";
import Footer from "../Components/Footer/Footer";
import Map from "../Components/map/map";
import Gallery from "../Components/Gallery/Gallery";

function LandingPage(props) {
  return (
    <>
      <Hero />
      <PartnerShip />
      <Enjoyment />
      <Features />
      <HouseFrom />
      <Pricing />
      <Gallery />
      <LandMark />
      <ClientFeedback />
      <Team />
      <Map />
      <Callus />
      <Footer />
    </>
  );
}

export default LandingPage;