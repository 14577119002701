import React from "react";
import { IoCall } from "react-icons/io5";
import style from "./Callus.module.scss";

function Callus(props) {
  return (
    <section>
      <div className={style.holdAnytime}>
        <div>
          <h1 className={style.Reach}>You Can Reach Us Anytime</h1>
          <p className={style.question}>
            Have a question or doubt about our property?
          </p>
        </div>
        <a href="tel:+2347061032706" target="_blank" rel="noopener noreferrer">
          <button className={style.callUs}>
            <IoCall />
            Call us
          </button>
        </a>
      </div>
    </section>
  );
}

export default Callus;
