import React from 'react';
import style from "./LandMark.module.scss"
import city from "../../Asset/city.png";
import filmCity from "../../Asset/filmCity.png";
import mania from "../../Asset/mania.png";
import central from "../../Asset/central.png";

function LandMark(props) {
    return (
      <section>
        <div className={style.holdLandMark}>
          <div className={style.holdLandMark2}>
            <div className={style.holdKapitalText}>
              <h1>Kapital City Estate Landmarks</h1>
              <p>
                Kapital City Estate is positioned in the heart of Epe sharing
                boundary with Alaro City, Proposed Lagos Smart City, Film City
                under Lagos State Government Idera Scheme
              </p>
            </div>

            <div className={style.holdCity}>
              <div className={style.city}>
                <img src={city} alt="city" className={style.Lora} />
                <h1>Alaro City</h1>
              </div>

              <div>
                <img src={mania} alt="mania" />
                <div className={style.lagosMan}>
                  <div className={style.lagosMan2}>
                    <div>
                      <h4>Lagos Food Security & Central Logistic Park</h4>
                      <p>
                        A logistics centre under construction in Ketu-Ereyun,
                        between Epe and Ikorodu.
                      </p>
                    </div>
                    <img
                      src={central}
                      alt="central"
                      className={style.holdCentralR}
                    />
                  </div>

                  <div className={style.lagosMan2}>
                    <img
                      src={central}
                      alt="central"
                      className={style.holdCentral}
                    />
                    <div>
                      <h4>Proposed Lagos Smart City</h4>
                      <p>
                        Lagos smart city is just 30 minutes away from Kapital
                        City Estate. They are in a very close Proximity
                      </p>
                    </div>
                  </div>
                </div>

                <div className={style.SecureNo}>
                  <a href="#form">Secure Now</a>
                </div>
              </div>

              <div className={style.filmCity}>
                <img src={filmCity} alt="city" className={style.Lora2} />
                <h1>Lagos Film City</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default LandMark;