import React, { useState } from "react";
import style from "./RequestCallModal.module.scss";
import { Link, useNavigate } from "react-router-dom";
import francesca from "../../../Asset/francesca.png";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { IoCloseSharp } from "react-icons/io5";

function RequestCallModal(props) {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);

  const current = new Date();
  const collectionRef = collection(db, "Request Call");
  const navigate = useNavigate();

  const handelCheck = () => {
    navigate("/");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    addDoc(collectionRef, {
      Name: fullName,
      Email: email,
      Phone: phone,
      city: city,
      time: time,
      date: date,
      dateReal: `${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()}`,
      read: false,
    })
      .then(() => {
        setFullName("");
        setEmail("");
        setCity("");
        setTime("");
        setDate("");
        setLoading(false);
        navigate("/success");
      })
      .catch(() => {
        setLoading(false);
        alert("Error");
      });
  };

  return (
    <section>
      <div className={style.holdModalAll}>
        <div className={style.holdModalAll2}>
          <div>
            <Link to={"/"}>
              <IoCloseSharp className={style.closeX} />
            </Link>
            <img src={francesca} alt="Kapital City" className={style.fran} />
          </div>
          <p className={style.successText}>
            Do you have any questions or enquires? Request for a call back
          </p>
          <div className={style.holdForm}>
            <form onSubmit={handleSubmit}>
              <div className={style.holdForm3}>
                <input
                  type="text"
                  placeholder="Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />

                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />

                <input
                  type="number"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />

                <input
                  type="text"
                  placeholder="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                />

                <input
                  type="date"
                  placeholder="Date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  required
                />

                <input
                  type="time"
                  placeholder="Time"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  required
                />
              </div>
              <div className={style.holdClose}>
                <button type="submit">
                  {loading ? "Please wait..." : "Request"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RequestCallModal;
