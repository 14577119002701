import React from 'react';
import styleParnter from "./PartnerShip.module.scss";
import pic1 from "../../Asset/pic1.png";
import pic2 from "../../Asset/pic2.png";
import pic3 from "../../Asset/pic3.png";
import pic4 from "../../Asset/pic4.png";
import pic5 from "../../Asset/pic5.png";


function PartnerShip(props) {
    return (
        <section>
            <div className={styleParnter.holdPartner}>
                <div>
                    <img src={pic1} alt="pic1" />
                    <img src={pic2} alt="pic2" />
                    <img src={pic3} alt="pic3" />
                    <img src={pic4} alt="pic4" />
                    <img src={pic5} alt="pic5" />
                </div>
            </div>
        </section>
    );
}

export default PartnerShip;