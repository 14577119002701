import React from "react";
import Menu from "../Menu/Menu";
import heroImage from "../../Asset/heroImage.png";
import heroImage2 from "../../Asset/heroImage2.png";
import heroImage3 from "../../Asset/heroImage3.png";
import style from "./Hero.module.scss";
import { FaWhatsapp } from "react-icons/fa";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";

function Hero(props) {
  const [count2, setCount2] = useState(0);

  const intervalId2 = (e) => {
    setCount2(e);
  };

  useEffect(() => {
    const intervalId = setTimeout(() => {
      setCount2(count2 + 1);
      if (count2 === 2) {
        setCount2(0);
      }
    }, 5000);
    return () => clearInterval(intervalId);
  }, [count2]);

  return (
    <section>
      {count2 === 0 ? (
        <motion.div
          initial={{ x: -100, y: 0, opacity: 0 }}
          animate={{ x: 0, y: 0, opacity: 1 }}
          exit={{ x: 100, y: 0, opacity: 0 }}
          className={style.holdAll}
        >
          <img src={heroImage} alt="Kapital City" className={style.imageKoko} />
          <div className={style.holdHeroContent}>
            <Menu />

            <div className={style.holdContent}>
              <p>
                <div></div>
                <span>The Kapital City</span>
              </p>

              <h1>Urban, Innovation and Lifestyle Reimagined</h1>

              <div className={style.holdButon}>
                <a href="#form" className={style.secure}>
                  Secure Now
                </a>
                <a
                  href="https://wa.me/+2347061032706"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={style.chatUs}
                >
                  <FaWhatsapp />
                  Chat Us{" "}
                </a>
              </div>
            </div>

            <div className={style.holdSwicher}>
              <span
                className={
                  count2 === 0 ? style.holdSwicher1 : style.holdSwicher2
                }
                onClick={() => {
                  intervalId2(0);
                }}
              ></span>
              <span
                className={
                  count2 === 1 ? style.holdSwicher1 : style.holdSwicher2
                }
                onClick={() => {
                  intervalId2(1);
                }}
              ></span>
              <span
                className={
                  count2 === 2 ? style.holdSwicher1 : style.holdSwicher2
                }
                onClick={() => {
                  intervalId2(2);
                }}
              ></span>
            </div>
          </div>
        </motion.div>
      ) : null}

      {count2 === 1 ? (
        <motion.div
          initial={{ x: -100, y: 0, opacity: 0 }}
          animate={{ x: 0, y: 0, opacity: 1 }}
          exit={{ x: 100, y: 0, opacity: 0 }}
          className={style.holdAll}
        >
          <img
            src={heroImage2}
            alt="Kapital City"
            className={style.imageKoko}
          />
          <div className={style.holdHeroContent}>
            <Menu />

            <div className={style.holdContent}>
              <p>
                <div></div>
                <span>The Kapital City</span>
              </p>

              <h1>The Biggest Urban and Lifestyle City</h1>

              <div className={style.holdButon}>
                <a href="#form" className={style.secure}>
                  Secure Now
                </a>
                <a
                  href="https://wa.me/+2347061032706"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={style.chatUs}
                >
                  <FaWhatsapp />
                  Chat Us{" "}
                </a>
              </div>
            </div>

            <div className={style.holdSwicher}>
              <span
                className={
                  count2 === 0 ? style.holdSwicher1 : style.holdSwicher2
                }
                onClick={() => {
                  intervalId2(0);
                }}
              ></span>
              <span
                className={
                  count2 === 1 ? style.holdSwicher1 : style.holdSwicher2
                }
                onClick={() => {
                  intervalId2(1);
                }}
              ></span>
              <span
                className={
                  count2 === 2 ? style.holdSwicher1 : style.holdSwicher2
                }
                onClick={() => {
                  intervalId2(2);
                }}
              ></span>
            </div>
          </div>
        </motion.div>
      ) : null}

      {count2 === 2 ? (
        <motion.div
          initial={{ x: -100, y: 0, opacity: 0 }}
          animate={{ x: 0, y: 0, opacity: 1 }}
          exit={{ x: 100, y: 0, opacity: 0 }}
          className={style.holdAll}
        >
          <img
            src={heroImage3}
            alt="Kapital City"
            className={style.imageKoko}
          />
          <div className={style.holdHeroContent}>
            <Menu />

            <div className={style.holdContent}>
              <p>
                <div></div>
                <span>The Kapital City</span>
              </p>

              <h1>The Heart of Entertainment and Lifestyle.</h1>

              <div className={style.holdButon}>
                <a href="#form" className={style.secure}>
                  Secure Now
                </a>
                <a
                  href="https://wa.me/+2347061032706"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={style.chatUs}
                >
                  <FaWhatsapp />
                  Chat Us{" "}
                </a>
              </div>
            </div>

            <div className={style.holdSwicher}>
              <span
                className={
                  count2 === 0 ? style.holdSwicher1 : style.holdSwicher2
                }
                onClick={() => {
                  intervalId2(0);
                }}
              ></span>
              <span
                className={
                  count2 === 1 ? style.holdSwicher1 : style.holdSwicher2
                }
                onClick={() => {
                  intervalId2(1);
                }}
              ></span>
              <span
                className={
                  count2 === 2 ? style.holdSwicher1 : style.holdSwicher2
                }
                onClick={() => {
                  intervalId2(2);
                }}
              ></span>
            </div>
          </div>
        </motion.div>
      ) : null}
    </section>
  );
}

export default Hero;
