import React from "react";
import photo1 from "../../Asset/photo1.png";
import photo2 from "../../Asset/photo2.png";
import photo3 from "../../Asset/photo3.png";
import photo4 from "../../Asset/photo4.png";
import photo5 from "../../Asset/photo5.png";
import photo6 from "../../Asset/photo6.png";
import photo7 from "../../Asset/photo7.png";
import faq from "../../Asset/KAPITAL CITY FORM AND FAQ.pdf";
import style from "./Gallery.module.scss";
import { FaGreaterThan, FaLessThan } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";

function Gallery(props) {
  const [count2, setCount2] = useState(0);

  const plus = () => {
    setCount2(count2 + 1);
    if (count2 === 6) {
      setCount2(0);
    }
  };

  const minus = () => {
    setCount2(count2 + 1);
    if (count2 === 0) {
      setCount2(0);
    }
  };

  useEffect(() => {
    const intervalId = setTimeout(() => {
      setCount2(count2 + 1);
      if (count2 === 6) {
        setCount2(0);
      }
    }, 3000);
    return () => clearInterval(intervalId);
  }, [count2]);

  return (
    <section>
      <div className={style.holdPriceing}>
        <div className={style.holdPriceing2}>
          <div className={style.galleryText}>
            <h1>Gallery </h1>
            <p>
              We put together the best ideas to implement and develop the
              best homes for you
            </p>
          </div>

          <div className={style.holdPhotos}>
            <FaLessThan onClick={minus} />

            {count2 === 0 && (
              <motion.div
                initial={{ x: -100, y: 0, opacity: 1 }}
                animate={{ x: 0, y: 0, opacity: 1 }}
                exit={{ x: 100, y: 0, opacity: 1 }}
              >
                <img src={photo1} alt="Kapital City" />
              </motion.div>
            )}
            {count2 === 1 && (
              <motion.div
                initial={{ x: -100, y: 0, opacity: 1 }}
                animate={{ x: 0, y: 0, opacity: 1 }}
                exit={{ x: 100, y: 0, opacity: 1 }}
              >
                <img src={photo2} alt="Kapital City" />
              </motion.div>
            )}
            {count2 === 2 && (
              <motion.div
                initial={{ x: -100, y: 0, opacity: 1 }}
                animate={{ x: 0, y: 0, opacity: 1 }}
                exit={{ x: 100, y: 0, opacity: 1 }}
              >
                <img src={photo3} alt="Kapital City" />
              </motion.div>
            )}
            {count2 === 3 && (
              <motion.div
                initial={{ x: -100, y: 0, opacity: 1 }}
                animate={{ x: 0, y: 0, opacity: 1 }}
                exit={{ x: 100, y: 0, opacity: 1 }}
              >
                <img src={photo4} alt="Kapital City" />
              </motion.div>
            )}
            {count2 === 4 && (
              <motion.div
                initial={{ x: -100, y: 0, opacity: 1 }}
                animate={{ x: 0, y: 0, opacity: 1 }}
                exit={{ x: 100, y: 0, opacity: 1 }}
              >
                <img src={photo5} alt="Kapital City" />
              </motion.div>
            )}
            {count2 === 5 && (
              <motion.div
                initial={{ x: -100, y: 0, opacity: 1 }}
                animate={{ x: 0, y: 0, opacity: 1 }}
                exit={{ x: 100, y: 0, opacity: 1 }}
              >
                <img src={photo6} alt="Kapital City" />
              </motion.div>
            )}
            {count2 === 6 && (
              <motion.div
                initial={{ x: -100, y: 0, opacity: 1 }}
                animate={{ x: 0, y: 0, opacity: 1 }}
                exit={{ x: 100, y: 0, opacity: 1 }}
              >
                <img src={photo7} alt="Kapital City" />
              </motion.div>
            )}

            <FaGreaterThan onClick={plus} />
          </div>

          <div className={style.holdLink}>
            <a href={faq} target="_self" download>
              Download Form& FAQ
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Gallery;
