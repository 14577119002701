import React, { useState } from "react";
import Logo from "../../Asset/Kapital City Logo.png";
import styleMenu from "./Menu.module.scss";
import { IoCall } from "react-icons/io5";
import { HiMenu } from "react-icons/hi";
import { Link } from "react-router-dom";

function Menu(props) {
  const [thug, setThug] = useState(false);

  const thugClik = () => {
    setThug(!thug);
  };
  return (
    <section className={styleMenu.holdAll}>
      <header>
        <img src={Logo} alt="" />
        <ul className={thug ? styleMenu.menuList : styleMenu.menuListNot}>
          <li>
            <a href="/">Contact</a>
          </li>
          <li>
            <a href="/">Pricing</a>
          </li>
          <li>
            <a href="/">FAQ</a>
          </li>
          <li>
            <Link to="/request">
              {" "}
              <IoCall />
              Request a Call
            </Link>
          </li>
        </ul>
        <a href="tel:+2347061032706" target="_blank" rel="noopener noreferrer">
          <button className={styleMenu.callUs}>
            <IoCall />
            Call Us
          </button>
        </a>
        {/* <button>Call US</button> */}
        <HiMenu className={styleMenu.menu} onClick={thugClik} />
      </header>
    </section>
  );
}

export default Menu;
