import './App.css';
import { Routes, Route } from "react-router-dom";
import LandingPage from "./Page/LandingPage";
import SuccessModal from "./Components/Modal/successModal/successModal";
import RequestCallModal from "./Components/Modal/RequestCallModal/RequestCallModal";
import Login from "./Components/Admin/Login/Login";
import AdminSmallForm from "./Components/Admin/SmallForm/SmallForm";
import Form1 from "./Components/Admin/Reservation/Reservation";
import From2 from "./Components/Admin/MakeEnquiry/MakeEnquiry";
import Index from "./Components/Admin";
import { Analytics } from "@vercel/analytics/react";

function App() {
  return (
    <main>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/success" element={<SuccessModal />} />
        <Route path="/request" element={<RequestCallModal />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/admin" element={<Index />}>
          <Route exact path="small-form">
            <Route exact index={true} element={<AdminSmallForm />} />
          </Route>
          <Route exact path="form1">
            <Route exact index={true} element={<Form1 />} />
          </Route>
          <Route exact path="form2">
            <Route index={true} element={<From2 />} />
          </Route>
        </Route>
      </Routes>
      <Analytics />
    </main>
  );
}

export default App;
