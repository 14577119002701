import React from 'react';
import logo from ".././../Asset/Kapital City Logo.png";
import { ImLocation } from "react-icons/im";
import { CgFileDocument } from "react-icons/cg";
import style from "./Pricing.module.scss";

function Pricing(props) {
    return (
      <section>
        <div className={style.holdPricing}>
          <div className={style.holdPricing2}>
            <div className={style.holdPriceText}>
              <h3>Pricing and Payment </h3>
              <p>
                Our Pricing plans are designed to be affordable, flexible, and
                tailored to your unique capability.
              </p>
            </div>

            <div className={style.holdRegistar}>
              <div className={style.Registered}>
                <img src={logo} alt="logo" />

                <div className={style.locationStyle}>
                  <ImLocation /> Epe, Lagos State Scheme
                </div>

                <div className={style.locationStyle}>
                  <CgFileDocument /> Registered Survey & C Of O in view
                </div>

                <h4 className={style.PriceLunch}>Pre-Launch Price</h4>

                <div className={style.threeNum}>300SQM/N4.8million</div>

                <h4 className={style.PriceLunch}>Market Price</h4>

                <div className={style.threeNum}>300SQM/N7million</div>

                <div className={style.line}></div>

                <p className={style.initial}>Initial Deposit: N1,000,000</p>

                <p className={style.initial2}>Spread payment for up to</p>

                <p className={style.initial3}>18 Months</p>
                <a href="#form" className={style.threeNumNum}>
                  Secure Now
                </a>
              </div>

              <div className={style.Registered2}>
                <img src={logo} alt="logo" />

                <div className={style.locationStyle}>
                  <ImLocation /> Epe, Lagos State Scheme
                </div>

                <div className={style.locationStyle}>
                  <CgFileDocument /> Registered Survey & C Of O in view
                </div>

                <h4 className={style.PriceLunch}>Pre-Launch Price</h4>

                <div className={style.threeNum2}>500SQM/N8million</div>

                <h4 className={style.PriceLunch}>Market Price</h4>

                <div className={style.threeNum2}>500SQM/N14million</div>

                <div className={style.line}></div>

                <p className={style.initial}>Initial Deposit: N1,000,000</p>

                <p className={style.initial2}>Spread payment for up to</p>

                <p className={style.initial3}>18 Months</p>
                <a href="#form" className={style.threeNumNum}>
                  Secure Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Pricing;