import React from "react";
import Style from "./HouseFrom.module.scss";
import key from "../../Asset/key.png";
import realstateAgent from "../../Asset/real-estate-agent.png";
import Form from "../Form/Form";

function HouseFrom(props) {
  return (
    <section className={Style.holdShould} id="form1">
      <div className={Style.holdShould2}>
        <div className={Style.holdShould3}>
          <h1>
            Why you should invest in <span>Kapital City Estate</span>
          </h1>
          <div className={Style.holdLine}>
            <div></div>
          </div>

          <h3>
            <img src={key} alt="Kapital City" /> For Home Owners
          </h3>

          <p>
            <span>Kapital City Estate</span> is situated in the heart of the
            vibrant city of Epe, the new Lagos, where urban living meets
            innovation. It offers you access to a thriving and dynamic
            community.
            <br />
            <br />
            With eco-friendly features, Kapital City Estate ensures a
            sustainable living environment, reducing your ecological footprint
            and potentially saving you money on utilities. The estate's
            strategic location offers easy access to key landmarks like Epe
            Resort, Alaro City, Pan Atlantic University, the International
            Airport, and the African Film City Project.
            <br />
            <br />
            This proximity opens up a world of opportunities for education,
            employment, and travel convenience. By investing in Kapital City
            Estate, you're not just investing in a home; you're investing in the
            future of Epe. Be a part of this vibrant and evolving community and
            embrace tomorrow today with Kapital City Estate!
          </p>

          <h3>
            <img src={realstateAgent} alt="Kapital City" />
            For Investors
          </h3>
          <p>
            <span>Kapital City Estate</span> is strategically located in Epe, a
            city showing promising growth prospects. The presence of major
            developments and infrastructure in the vicinity indicates the
            potential for property values to appreciate. As an investor, this
            can translate to capital appreciation, allowing you to sell the
            property at a higher price in the future. Real estate can be an
            effective way to diversify your investment portfolio.
            <br />
            <br />
            By investing in Kapital City Estate, you can add a tangible asset to
            your portfolio, reducing your exposure to other asset classes and
            spreading risk.
          </p>
        </div>
        <Form />
      </div>
    </section>
  );
}

export default HouseFrom;
