import React, { useState } from "react";
import style from "./Features.module.scss";
import featureImage from "../../Asset/features2.png";
import beach1 from "../../Asset/beach 1.png";
import beach2 from "../../Asset/beach 2.png";
import beach3 from "../../Asset/beach 3.png";
import beach4 from "../../Asset/beach 4.png";
import beach5 from "../../Asset/beach 5.png";
import { IoCloseSharp } from "react-icons/io5";

function Features(props) {
  const [video, setVideo] = useState(false);

  const videoButin = () => {
    setVideo(!video);
  };
  return (
    <section>
      <div className={style.holdFeature}>
        <div className={style.holdFeature2}>
          <h5>Features</h5>
          <div className={style.holdContent}>
            <div className={style.holdContentText}>
              <h1>Kapital City Provides you with the following Features.</h1>
              <div className={style.nowSwitch}>
                <img
                  src={featureImage}
                  alt="Kapital City"
                  onClick={videoButin}
                />
              </div>
              <div className={style.holdBeachs}>
                <div className={style.holdBeachs1}>
                  <img src={beach1} alt="beach" />
                </div>
                <div>
                  <h3>Good Road Network</h3>
                  <p>
                    Well-maintained and well-constructed roads within the
                    estate, facilitating smooth and convenient transportation
                    within the community.
                  </p>
                </div>
              </div>
              <div className={style.holdBeachs}>
                <div className={style.holdBeachs2}>
                  <img src={beach3} alt="beach" />
                </div>
                <div>
                  <h3>Drainage System</h3>
                  <p>
                    Proper drainage infrastructure to prevent water logging and
                    ensure effective water management within the estate.
                  </p>
                </div>
              </div>
              <div className={style.holdBeachs}>
                <div className={style.holdBeachs3}>
                  <img src={beach4} alt="beach" />
                </div>
                <div>
                  <h3>Recreational Area</h3>
                  <p>
                    A designated space for leisure and recreational activities,
                    providing opportunities for relaxation and social
                    interaction.
                  </p>
                </div>
              </div>
              <div className={style.holdBeachs}>
                <div className={style.holdBeachs3}>
                  <img src={beach5} alt="beach" />
                </div>
                <div>
                  <h3>Interlocking Road</h3>
                  <p>
                    The estate features well-maintained interlocking roads for
                    smooth transportation and accessibility.
                  </p>
                </div>
              </div>
              <div className={style.holdBeachs}>
                <div className={style.holdBeachs3}>
                  <img src={beach2} alt="beach" />
                </div>
                <div>
                  <h3>Security Surveillance</h3>
                  <p>
                    Surveillance systems and security measures to monitor and
                    safeguard the estate, enhancing the safety and peace of mind
                    of residents.
                  </p>
                </div>
              </div>
            </div>
            <div className={style.holdContentImage}>
              <img src={featureImage} alt="Kapital City" onClick={videoButin} />
              <div className={style.secureButin}>
                <a href="#form">Secure Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={video ? style.holdModalAll : style.holdModalAllMG}>
        <div className={style.holdModalAll2}>
          <div className={style.holdCeremonyHead}>
            <h1>What to Expect in Kapital City</h1>
            <IoCloseSharp onClick={videoButin} />
          </div>
          <div className={style.holdCeremonyVideo}>
            <div>
              <iframe
                src={
                  video &&
                  "https://www.youtube.com/embed/LtkjMz5ERpU?si=416EJJpH4ALu6wN1"
                }
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className={style.holdClose}>
            <button onClick={videoButin}>Close</button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
