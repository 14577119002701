// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBOKEHMzukgKeqw0OyQzJeqdSLH8gmpM-s",
  authDomain: "kapitalcitys.firebaseapp.com",
  projectId: "kapitalcitys",
  storageBucket: "kapitalcitys.appspot.com",
  messagingSenderId: "671358283785",
  appId: "1:671358283785:web:273c1be4a161c6d1e708d8",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
