import React from "react";
import style from "./Team.module.scss";
import chairman from "../../Asset/chairman.png";
import ceo from "../../Asset/ceo.png";
import gmd from "../../Asset/gmd.png";
import wasiu from "../../Asset/wasiu.png";
import lateef from "../../Asset/lateef.png";

function Team(props) {
  return (
    <section>
      <div className={style.holdFeedBack}>
        <div className={style.holdFeedBack2}>
          <div className={style.clientTextHead}>
            <h2>Meet The Team</h2>
            <p>Meet The Amazing Team Behind the Sophisticated Kapital City</p>
          </div>

          <div className={style.teams}>
            <div className={style.teams2}>
              <div>
                <img src={chairman} alt="chairman" />
                <h4>Dr Oluwatosin Olatujoye</h4>
                <p>CEO, Zylus Homes</p>
              </div>
              <div>
                <img src={ceo} alt="ceo" />
                <h4>Mrs Bukola Olatujoye</h4>
                <p>ED, Operation & Admin</p>
              </div>
              <div>
                <img src={gmd} alt="chairman" />
                <h4>Ojo Olugbenga</h4>
                <p>GMD/COO</p>
              </div>
              <div>
                <img src={wasiu} alt="chairman" />
                <h4>Wasiu Sonekan</h4>
                <p>ED, Finance</p>
              </div>
              <div>
                <img src={lateef} alt="chairman" />
                <h4>Lateef Lawal</h4>
                <p>ED, Real Estate</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
