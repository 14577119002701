/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
/* eslint-disable indent */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { Form, Button } from 'react-bootstrap';
import loginStyle from "./Login.module.scss";
import Arizonlogo from "../../../Asset/Kapital City Logo.png";
import { useDispatch } from "react-redux";
import { login, logout } from "../../../features/auth";

export default function Login() {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  sessionStorage.setItem("Logged", success);

  const handleLogin = (e) => {
    e.preventDefault();
    if (email === "olatoyinbotoheeb@gmail.com" && password === "Olatoyinbo1") {
      navigate("/admin");
      dispatch(login());
      setSuccess(true);
    } else if (email === "default@gmail.com" && password === "default") {
      navigate("/admin");
      setSuccess(true);
      dispatch(login());
    } else {
      alert("Wrong password");
    }
  };
  return (
    <section className={loginStyle.holdAll}>
      <div className={loginStyle.holdFormNText}>
        <div className={loginStyle.holdText}>
          <div className={loginStyle.holdImage}>
            <img src={Arizonlogo} alt="Logo" />
          </div>
          <div>
            <h3>Arizon Estate</h3>
            <p>
              Arizon Estate not only guarantees you the work-life balance you
              deserve but also brings to you a perfect blend with technology and
              nature.
            </p>
          </div>
        </div>
        <div className={loginStyle.holdForm}>
          <div className={loginStyle.holdImage}>
            <img src={Arizonlogo} alt="Logo" />
          </div>
          <div className={loginStyle.headerText}>
            <h3>Welcome Back!</h3>
            <p>
              Please sign in to your personal account to start access all
              services.
            </p>
          </div>
          <form onSubmit={handleLogin}>
            <p className={loginStyle.inSwi}>Login with email and password</p>
            <div className={loginStyle.holdFromEmail}>
              <label>Email</label>
              <br />
              <input
                type="email"
                placeholder="Enter email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className={loginStyle.holdFromEmail}>
              <label>Password</label>
              <br />
              <input
                type="password"
                placeholder="Password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
      {/* {isLoading ? <Loader /> : null} */}
    </section>
  );
}
