import React, { useState } from "react";
import alabi from "../../Asset/alabi.png";
import feedbackvideo from "../../Asset/feedback video.png";
import style from "./ClientFeedback.module.scss";
import { IoCloseSharp } from "react-icons/io5";

function ClientFeedback(props) {
  const [video, setVideo] = useState(false);

  const videoButin = () => {
    setVideo(!video);
  };
  return (
    <section>
      <div className={style.holdFeedBack}>
        <div className={style.holdFeedBack2}>
          <div className={style.clientTextHead}>
            <h2>Client’s Feedbacks</h2>
            <p>
              Check out what some of the feedbacks from our latest investors
              after buying land some of our residential and commercial
              properties.
            </p>
          </div>

          <div className={style.LoremSimply1}>
            <div className={style.LoremSimply2}>
              <div className={style.LoremSimply1Pro}>
                <p>
                  We have always set a hallmark for great & sophisticated urban
                  living and unique and elegant features . Zylus Homes always
                  deliver the absolute best, never failing and faltering. It is
                  always a pleasure doing business with you.
                </p>

                <p>Thank you, Zylus Homes.</p>

                <div>
                  <img src={alabi} alt="alabi" />
                  <div>
                    <h4>Olorunda Taiwo</h4>
                    <p>Clients</p>
                  </div>
                </div>
              </div>
              <div className={style.LoremSimply1Pro}>
                <p>
                  It has been amazing doing business with Zylus homes unlike
                  other firms that don’t get allocated on time. Zylus has made
                  every process seamless from payment to allocation. Thank you
                  Zylus for making my dream come through within 1 month.
                </p>

                {/* <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the
                </p> */}

                <div>
                  <img src={alabi} alt="alabi" />
                  <div>
                    <h4>Alade Kemi</h4>
                    <p>Clients</p>
                  </div>
                </div>
              </div>
            </div>

            <div className={style.video}>
              <img
                src={feedbackvideo}
                alt="feedback video"
                onClick={videoButin}
              />
            </div>

            <div className={style.LoremSimply3}>
              <div className={style.LoremSimply1Pro}>
                <p>
                  Tiwa is a wonderful place to have a plot in and coming here to
                  see it myself is like a dream come through for everyone who
                  intends to grow and be wealthy. Zylus homes thank you for
                  giving me the opportunity to secure a plot here.
                </p>

                {/* <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the
                </p> */}

                <div>
                  <img src={alabi} alt="alabi" />
                  <div>
                    <h4>Fisayo Oloruniyi</h4>
                    <p>Clients</p>
                  </div>
                </div>
              </div>
              <div className={style.LoremSimply1Pro}>
                <p>
                  I couldn't be happier with my decision to invest in Zylus
                  Homes. It has been a remarkable experience from the very
                  beginning.I can confidently say that Kapital City Estate has
                  exceeded my expectations as an investor. It's not just an
                  investment;
                </p>

                <p>
                  it's a wise decision for a secure and promising future. I look
                  forward to seeing the growth and development of this community
                  and am proud to be a part of it.
                </p>

                <div>
                  <img src={alabi} alt="alabi" />
                  <div>
                    <h4>Alabi Samuel Seun</h4>
                    <p>Clients</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={style.secureNow}>
            <a href="#form">Secure Now</a>
          </div>
        </div>
      </div>
      <div className={video ? style.holdModalAll : style.holdModalAllMG}>
        <div className={style.holdModalAll2}>
          <div className={style.holdCeremonyHead}>
            <h1>Client’s Feedbacks</h1>
            <IoCloseSharp onClick={videoButin} />
          </div>
          <div className={style.holdCeremonyVideo}>
            <div>
              <iframe
                src={
                  video &&
                  "https://www.youtube.com/embed/hw9yaJ5og14?si=axfW5dmKRYufn1Nl"
                }
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className={style.holdClose}>
            <button onClick={videoButin}>Close</button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ClientFeedback;
