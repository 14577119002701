import React, { useState } from "react";
import Style from "./Form.module.scss";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { useNavigate } from "react-router-dom";

function Form(props) {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [purchase, setPurchase] = useState("");
  const [realtor, setRealtor] = useState("");
  const [hear, setHear] = useState("");
  const [soon, setSoon] = useState("");
  const [country, setCountry] = useState("");
  const [loading, setLoading] = useState(false);

  const current = new Date();
  const collectionRef = collection(db, "Kapital City");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    addDoc(collectionRef, {
      Name: fullName,
      Email: email,
      Phone: phone,
      Purchase: purchase,
      realtor: realtor,
      hear: hear,
      soon: soon,
      country: country,
      date: `${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()}`,
      read: false,
    })
      .then(() => {
        setFullName("");
        setEmail("");
        setPhone("");
        setPurchase("");
        setRealtor("");
        setHear("");
        setSoon("");
        setLoading(false);
        navigate("/success");
      })
      .catch(() => {
        setLoading(false);
        alert("Error");
      });
  };

  return (
    <section className={Style.holdFrom} id="form">
      <div className={Style.holdFrom2}>
        <h1 className={Style.holdFrom2H1}>WANT TO SECURE YOUR PLOT TODAY ?</h1>
        <div className={Style.holdRedLine}>
          <div></div>
        </div>
        <form className={Style.holdForm3} onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
          />

          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <input
            type="number"
            placeholder="Phone Number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />

          <input
            type="text"
            placeholder="Country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            required
          />

          <select
            value={realtor}
            onChange={(e) => setRealtor(e.target.value)}
            required
          >
            <option value="">What are you interested in ?</option>
            <option value="500 SQM">500 SQM</option>
            <option value="300 SQM">300 SQM</option>
            <option value="Other">Other</option>
          </select>

          <select
            value={purchase}
            onChange={(e) => setPurchase(e.target.value)}
            required
          >
            <option value="">How many plot do you want to buy ?</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>

          {/* <input
                      type="number"
                      value={}
                      onChange={(e) => (e.target.value)}
                      placeholder="How many plot do you want to buy ?"
                      required
                    /> */}

          <select
            value={soon}
            onChange={(e) => setSoon(e.target.value)}
            required
          >
            <option value="">
              How soon do you want to purchase your land ?
            </option>
            <option value="Immediately">Immediately</option>
            <option value="Within a week">Within a week</option>
            <option value="Within a month">Within a month</option>
          </select>

          <select
            value={hear}
            onChange={(e) => setHear(e.target.value)}
            required
          >
            <option value="">How did you hear about us ?</option>
            <option value="Facebook">Facebook</option>
            <option value="Billboard">Billboard</option>
            <option value="Instagram"> Instagram</option>
            <option value="Twitter">Twitter</option>
            <option value="Google">Google</option>
            <option value="LinkedIn">LinkedIn</option>
            <option value="Referred by someone">Referred by someone</option>
          </select>

          <button type="submit">
            {loading ? "Please wait..." : "Submit Purchase Interest"}
          </button>
        </form>
      </div>
    </section>
  );
}

export default Form;
