import React, { useState, useEffect } from "react";
import { AiOutlineLogout } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { Link, Outlet } from "react-router-dom";
import IndexStyles from "./index.module.scss";
import Category from "../../Asset/Category1.svg";
import not from "../../Asset/not.svg";
import Arizonlogo from "../../Asset/Kapital City Logo.png"
import user from "../../Asset/User.svg";
import { logout } from "../../features/auth";
import { useDispatch } from "react-redux";

export default function Index() {
  const dispatch = useDispatch();
  const [navValue, setNavValue] = useState("");
  useEffect(() => {
    const tabs = () => {
      setNavValue(window.location.pathname);
    };
    tabs();
  });

  const tabs = () => {
    setNavValue(window.location.pathname);
  };

  return (
    <React.Fragment>
      <div className={IndexStyles.holdAll}>
        <div className={IndexStyles.NavBar}>
          <div
            className={IndexStyles.NavBar2}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={Arizonlogo}
              alt="logo"
              style={{
                height: "5em",
                marginBottom: "2em",
              }}
            />
          </div>
          <ul
            style={{
              paddingLeft: 0,
            }}
          >
            <li className={IndexStyles.list} onClick={tabs}>
              <Link
                to="small-form"
                className={
                  navValue === "/admin/small-form"
                    ? IndexStyles.active
                    : IndexStyles.listLink
                }
              >
                <img
                  src={Category}
                  alt="logo"
                  className={IndexStyles.iconSvg}
                />
                Kapital City
              </Link>
            </li>
            {/* <li className={IndexStyles.list} onClick={tabs}>
              <Link
                to="form1"
                className={
                  navValue === "/admin/form1"
                    ? IndexStyles.active
                    : IndexStyles.listLink
                }
              >
                <img src={user} alt="logo" className={IndexStyles.iconSvg} />
                Request Call
              </Link>
            </li> */}
            <li className={IndexStyles.list} onClick={tabs}>
              <Link
                to="form2"
                className={
                  navValue === "/admin/form2"
                    ? IndexStyles.active
                    : IndexStyles.listLink
                }
              >
                <img src={user} alt="logo" className={IndexStyles.iconSvg} />
                Request Call
              </Link>
            </li>
            {/* <li className={IndexStyles.list} onClick={tabs}>
              <Link
                to="reservationhub"
                className={
                  navValue === "/admin/reservationhub"
                    ? IndexStyles.active
                    : IndexStyles.listLink
                }
              >
                <img src="" alt="logo" className={IndexStyles.iconSvg} />
                Reservation Hub
              </Link>
            </li> */}
            {/* <li className={IndexStyles.list} onClick={tabs}>
              <Link
                to="contacthub"
                className={
                  navValue === "/admin/contacthub"
                    ? IndexStyles.active
                    : IndexStyles.listLink
                }
              >
                <img src="" alt="logo" className={IndexStyles.iconSvg} />
                Contact Hub
              </Link>
            </li> */}
            <li
              className={IndexStyles.list}
              onClick={() => {
                sessionStorage.setItem("Logged", false);
                dispatch(logout());
                tabs();
              }}
            >
              <Link
                to="/"
                className={
                  navValue === "/" ? IndexStyles.active : IndexStyles.listLink
                }
              >
                <AiOutlineLogout className={IndexStyles.iconSvg} />
                Log out
              </Link>
            </li>
          </ul>
        </div>

        <div className={IndexStyles.holdHeader}>
          <div className={IndexStyles.holdFlex}>
            <div>
              <input
                type="search"
                placeholder="search"
                style={{ outline: "none", border: "none" }}
              />
              <BiSearch />
            </div>
            <div className={IndexStyles.headerSec}>
              <div className={IndexStyles.headerNoti}>
                <img src={not} alt="bell" />
                <div className={IndexStyles.headerNotiBall}></div>
              </div>
              <div>
                <span>Toheeb Olatoyinbo</span>
              </div>
            </div>
          </div>
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
