import React from "react";
import logo from "../../Asset/Kapital City Logo.png";
import { ImFacebook, ImYoutube } from "react-icons/im";
import { AiOutlineInstagram } from "react-icons/ai";
import FooterStyle from "./Footer.module.scss";
import { Link } from "react-router-dom";

function Footer(props) {
  return (
    <section>
      <div className={FooterStyle.holdAll}>
        <div className={FooterStyle.Location}>
          <img src={logo} alt="logo" />
          <h5>Our Location</h5>
          <h3>Head Office.</h3>
          <p>
            4A Omon Ebhomenye Street, Off Admiralty Way, Lekki Phase 1
            <br />
            <h3>Mainland Cooperate Office</h3>
            65, Allen Avenue, First Bank Bus-shrefp, Ikeja, Lagos, Nigeria.
          </p>
        </div>
        <div className={FooterStyle.AboutTiwa}>
          <ul>
            <h5>Pricing</h5>
            <li>
              <a href="/">Testimonials</a>
            </li>
          </ul>
        </div>
        <div className={FooterStyle.Support}>
          <ul>
            <h5>Support</h5>
            <li>
              <Link to="/request">Request a Call</Link>
            </li>
            <li>
              <a href={"/"}>Download FAQ</a>
            </li>
          </ul>
        </div>
        <div className={FooterStyle.Support1}>
          <h5>Follow Us</h5>
          <div>
            <a
              href="https://www.facebook.com/groups/1107458949860758/"
              target="_blank"
              rel="noreferrer"
            >
              <div>
                <ImFacebook />
              </div>
            </a>
            <a
              href="https://www.instagram.com/zylushomes/"
              target="_blank"
              rel="noreferrer"
              style={{ margin: "0 10px" }}
            >
              <div>
                <AiOutlineInstagram />
              </div>
            </a>
            <a
              href="https://www.youtube.com/@zylustv6590"
              target="_blank"
              rel="noreferrer"
            >
              <div>
                <ImYoutube />
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
