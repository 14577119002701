/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import Layout1Style from "./SmallForm.module.scss";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
// import { db } from "../../../firebase-config";
// import { CSVLink } from "react-csv";

function AdminSmallForm() {
  const [thug, setThug] = useState(false);
  const [current, setCurrent] = useState(0);
  const [dataIndex, setDataIndex] = useState();
  const [dataIndex3, setDataIndex3] = useState({});
  const [inputText, setInputText] = useState("");
  const [datas, setDatas] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const contactCollectionRef = collection(db, "Kapital City");

  const handleCurrent = (index) => {
    setCurrent(index);
  };

  let inputHandler = (e) => {
    let lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const filteredData = datas.filter((el) => {
    if (inputText === "") {
      return el;
    } else {
      return el.email.toLowerCase().includes(inputText);
    }
  });

  const handleGetData = () => {
    getDocs(contactCollectionRef)
      .then((response) => {
        setDatas(response.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        console.log(datas);
        setDataIndex(datas.length);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const handleDeleteData = (id) => {
    const reservationDoc = doc(db, "Kapital City", id);
    deleteDoc(reservationDoc)
      .then((response) => {
        alert("Success");
        handleGetData();
      })
      .catch((err) => {
        alert("Error: " + err);
      });
    handleClose();
  };

  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <div className={Layout1Style.holdAll}>
      <div className={Layout1Style.holdAll2}>
        <h4 className={Layout1Style.textHead}>Small</h4>
        <div className={Layout1Style.holdDateSearch}>
          <div>
            <input
              type="search"
              placeholder="Search by email..."
              className={Layout1Style.search}
              onChange={inputHandler}
            />
          </div>
          <div>
            <div>
              <h6>Total List: {dataIndex}</h6>
            </div>
          </div>
          {/* <div>
            <div>
              <CSVLink data={datas}>
                <button>Export CSV</button>
              </CSVLink>
            </div>
          </div> */}
        </div>
        <div>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <td>Name</td>
                <td>Email</td>
                <td>Phone</td>
                <td>Date</td>
                <td>Ready</td>
                <td></td>
              </tr>
            </thead>
            <tbody className={Layout1Style.holdTable}>
              {filteredData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.Name}</td>
                    <td>{item.Email}</td>
                    <td>{item.Phone}</td>
                    <td>{item.date}</td>
                    <td className={
                          item.read ? Layout1Style.compl : Layout1Style.pend
                        }> {item.read ? "Verified" : "Pending"}</td>
                    <td>
                      <button
                        style={{ marginLeft: "10px" }}
                        className="blackNwhite"
                        onClick={() => {
                          handleDeleteData(item.id);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AdminSmallForm;
