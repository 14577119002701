import React from "react";
import Layout1 from "./Layout/Layout1";

function Form1() {
  return (
    <React.Fragment>
      <div>
        <Layout1 />
      </div>
    </React.Fragment>
  );
}

export default Form1;
